import {
  useExperimentValue,
  useFeatureFlag,
} from '@seek/candidate-feature-flag';

import {
  DynamicPillsV2,
  REFINE_BAR_V2_FEATURE_FLAG,
  RefineBarV2,
  SAVED_AND_RECENT_SEARCHES_FEATURE_FLAG,
  StickySearchBar,
  type RefineBarV2Experiment,
  type SavedAndRecentSearchesExperiment,
} from 'src/config/experiments';
import {
  LD_HOMEPAGE_GENAI_CHAT_FEATURE_FLAG,
  type LDHomepageGenAIChatFeatureFlag,
} from 'src/config/featureFlags';
import { useCandidateDetails } from 'src/modules/hooks/useCandidateDetailsQuery.ts';

import { useSelector } from '../react';

import { selectFeatureFlag } from './selectors';

export const useIsHomepageRecsBadgingStrongApplicantEnabled = () =>
  useSelector(selectFeatureFlag('homepageRecsBadgingStrongApplicant'));

export const useIsHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled = () =>
  useSelector(
    selectFeatureFlag('homepageRecsBadgingExpiringSoonEarlyApplicant'),
  );

export const useIsHomepageGenAIChatKillSwitchEnabled = () =>
  !useLdHomepageGenAIChatFeatureFlag();

export const useIsHomepageGenAIChatEnabled = () => {
  const isHomepageGenAIChatEnabled = useSelector(
    selectFeatureFlag('homepageGenAIChat'),
  );
  const isKillSwitchEnabled = useIsHomepageGenAIChatKillSwitchEnabled();

  const allowedList: number[] = [582665568, 34304079];
  const { id: seekerId } = useCandidateDetails();

  return (
    !isKillSwitchEnabled &&
    (isHomepageGenAIChatEnabled ||
      Boolean(seekerId && allowedList.includes(seekerId)))
  );
};

export const useIsHomepageGenAIChatAltModelEnabled = () =>
  useSelector(selectFeatureFlag('homepageGenAIChatAltModel'));

export const useFeatureFlagRefineBarV2Experiment = () => {
  const value = useExperimentValue<RefineBarV2Experiment>(
    REFINE_BAR_V2_FEATURE_FLAG,
  );

  return {
    dynamicPillsV2: value === DynamicPillsV2,
    stickySearchBar: value === StickySearchBar,
    refineBarV2: value === RefineBarV2,
  };
};

export const useSavedAndRecentSearchesFeatureFlag = () => {
  const value = useExperimentValue<SavedAndRecentSearchesExperiment>(
    SAVED_AND_RECENT_SEARCHES_FEATURE_FLAG,
  );

  return value === 'saved-and-recent-searches';
};

export const useLdHomepageGenAIChatFeatureFlag = () => {
  const value = useFeatureFlag<LDHomepageGenAIChatFeatureFlag>(
    LD_HOMEPAGE_GENAI_CHAT_FEATURE_FLAG,
  );

  return value;
};
