import type { Zone } from '@seek/audience-zones';
import type { SearchParams } from '@seek/chalice-types';
import type { Brand, Locale } from '@seek/melways-sites';
import checksum from '@seek/request-checksum';
import axios from 'axios';
import get from 'lodash/get';
import has from 'lodash/has';

import {
  environment,
  searchUrlV5 as jobSearchUrlV5,
  personalisedSearchUrlV5 as personalisedJobSearchUrlV5,
  v5CountsUrl,
  v5CountUrl,
  v5PersonalisedCountsUrl,
  v5PersonalisedCountUrl,
} from 'src/config';
import getCountryFromZone from 'src/config/utils/getCountryFromZone';
import clean from 'src/modules/clean-object';
import {
  createAuthenticatedHttpClient,
  createUnauthenticatedHttpClient,
  withDevAuth,
  withLastKnownSolUserId,
  withRequestId,
  withServerHeaders,
} from 'src/modules/seek-api-request';
import { convertToApiQuery } from 'src/modules/seek-jobs-search-query';
import { shouldRequestRelatedSearches } from 'src/modules/seek-jobs-search-query/convertToApiQueryHelper';
import type { CookieType } from 'src/types/cookie';
import type { Country } from 'src/types/globals';
import type { TestHeaders } from 'src/utils/productionTesting/productionTesting';

import { getJobSearchDarkProdUrl } from './utils/getJobSearchDarkProdUrl';

// Matches the jobsearch api timeout. Speak to the #jobsearch
// team when changing this value.
export const SEARCH_API_TIMEOUT = 3000;

interface BaseAPIProps {
  searchParams: SearchParams;
  brand?: Brand;
  country: Country;
  zone: Zone;
  cookies: Record<string, any>;
  userClientId: string;
  sessionId: string;
  requestId?: string;
  timeout?: number;
  testHeaders: TestHeaders;
  locale: Locale;
  isAuthenticated?: boolean;
  solId: string;
  serverProps?: Record<string, string | undefined>;
}

let onlySearchPromise: any,
  onlyCountsPromise: Promise<any> | undefined,
  onlyCountPromise: Promise<any> | undefined,
  // Use AxiosType from ca-http-client once moved to ts
  onlySearchPromiseSource: any,
  onlyCountsPromiseSource: any,
  onlyCountPromiseSource: any;

function buildApiQuery({
  searchQuery,
  country,
  zone,
  userQueryId,
  seekerId,
  solId,
  locale,
  relatedSearchesCount,
  userClientId,
  sessionId,
  baseKeywords,
  source,
  newSince,
}: {
  searchQuery: SearchParams;
  country: Country;
  zone: Zone;
  userClientId: string;
  sessionId: string;
  userQueryId?: string;
  seekerId?: number;
  solId: string;
  locale: Locale;
  relatedSearchesCount?: number;
  baseKeywords?: string;
  source?: string;
  newSince?: number;
}) {
  const tags = searchQuery.tags;
  const analyticsParams = {
    userqueryid: userQueryId || null,
    userid: userClientId || null,
    usersessionid: userClientId || null,
    eventCaptureSessionId: sessionId || null,
  };

  // TODO-ZONES: See if we can update the library so we can pass in Zone
  // instead of country value
  const searchParams = convertToApiQuery({
    country,
    searchQuery,
    zone,
  });

  const isLocationOnlySerp = Boolean(
    !searchQuery.keywords && !searchQuery.classification && searchQuery.where,
  );

  const isLocationAndWorkTypeSerp = Boolean(
    isLocationOnlySerp && searchQuery.worktype,
  );

  const requestFacets = isLocationOnlySerp || isLocationAndWorkTypeSerp;

  const cleanApiQuery = clean({
    newSince,
    siteKey: `${getCountryFromZone(zone)}-Main`,
    sourcesystem: 'houston',
    facets: requestFacets ? 'distinctTitle' : '',
    ...analyticsParams,
    ...{
      ...searchParams,
      baseKeywords: baseKeywords ? baseKeywords : searchParams?.keywords,
      include: searchParams?.include
        ? `${searchParams?.include},pills`
        : `pills`,
    },
    ...{
      tags,
    },
    locale,
    seekerId,
    solId,
    ...(shouldRequestRelatedSearches({ searchParams, zone }) && {
      relatedSearchesCount,
    }),
    ...(source && { source }),
  });

  return {
    ...cleanApiQuery,
    baseKeywords: cleanApiQuery?.baseKeywords
      ? cleanApiQuery?.baseKeywords
      : '',
  };
}

const buildApiHeaders = (requestId?: string) => ({
  ...withRequestId(requestId),
});

const getAuthClientDetails = (isAuthenticated?: boolean) => {
  // For dev environments, we use an unauthenticated request and set the Authorization in the header manually (see withDevAuth)
  const useDevAuth =
    environment === 'development' ||
    environment === 'dev' ||
    environment === 'dark-prod';
  return {
    isAuthenticatedClient: isAuthenticated && ENV.CLIENT && !useDevAuth,
    useDevAuth,
  };
};

const getCountsUrl = ({
  isAuthenticated = false,
  cookies,
}: {
  isAuthenticated?: boolean;
  cookies: CookieType;
}) => {
  const darkProdSearchUrlCounts = getJobSearchDarkProdUrl({
    cookies,
    path: '/counts',
  });

  if (darkProdSearchUrlCounts) {
    return darkProdSearchUrlCounts;
  }

  if (isAuthenticated) {
    return v5PersonalisedCountsUrl;
  }
  return v5CountsUrl;
};

const getCountUrl = ({
  isAuthenticated = false,
  cookies,
}: {
  isAuthenticated?: boolean;
  cookies: CookieType;
}) => {
  const darkProdSearchUrlCount = getJobSearchDarkProdUrl({
    cookies,
    path: '/count',
  });

  if (darkProdSearchUrlCount) {
    return darkProdSearchUrlCount;
  }

  if (isAuthenticated) {
    return v5PersonalisedCountUrl;
  }
  return v5CountUrl;
};

function getSearchUrl({
  isAuthenticated,
  cookies,
}: {
  isAuthenticated: boolean;
  cookies: CookieType;
}) {
  const darkProdSearchUrlSearch = getJobSearchDarkProdUrl({
    cookies,
    path: '/search',
  });

  if (darkProdSearchUrlSearch) {
    return darkProdSearchUrlSearch;
  }

  if (isAuthenticated) {
    return personalisedJobSearchUrlV5;
  }
  return jobSearchUrlV5;
}

function search({
  searchParams,
  brand,
  country,
  zone,
  cookies,
  userQueryId,
  requestId,
  timeout = SEARCH_API_TIMEOUT,
  userAgent,
  sessionId,
  userClientId,
  seekerId,
  solId,
  testHeaders,
  locale,
  serverProps,
  isAuthenticated,
  relatedSearchesCount,
  baseKeywords,
  source,
  newSince,
}: BaseAPIProps & {
  userQueryId: string;
  userAgent: string;
  seekerId?: number;
  serverProps?: Record<string, string | undefined>;
  isAuthenticated: boolean;
  relatedSearchesCount?: number;
  baseKeywords?: string;
  source?: string;
  newSince?: number;
}) {
  if (onlySearchPromise) {
    onlySearchPromiseSource.cancel();
  }

  const apiQuery = buildApiQuery({
    baseKeywords,
    searchQuery: searchParams,
    country,
    zone,
    userQueryId,
    seekerId,
    solId,
    locale,
    relatedSearchesCount,
    source,
    sessionId,
    userClientId,
    newSince,
  });

  const { isAuthenticatedClient, useDevAuth } =
    getAuthClientDetails(isAuthenticated);

  const config = {
    retryPolicy: { retries: 0 },
    defaultRequestConfig: {
      headers: {
        ...testHeaders,
        ...withLastKnownSolUserId(cookies['last-known-sol-user-id']),
        ...(useDevAuth ? withDevAuth(cookies.AUTH_TOKEN) : {}),
      },
    },
  };

  const httpClient = isAuthenticatedClient
    ? createAuthenticatedHttpClient(config)
    : createUnauthenticatedHttpClient(config);

  const serverHeaders = withServerHeaders({
    userAgent,
    ...serverProps,
  });

  const searchPromise = httpClient
    .send({
      url: getSearchUrl({ isAuthenticated, cookies }),
      timeout,
      params: apiQuery,
      label: 'jobsearch-api-search',
      cancelToken: get(onlySearchPromiseSource, 'token'),
      headers: {
        ...buildApiHeaders(requestId),
        'x-seek-checksum': checksum(apiQuery),
        'seek-request-brand': brand,
        'seek-request-country': country,
        ...serverHeaders,
      },
    })
    .then((response: Record<string, any>) => {
      const result = get(response, 'data');

      if (has(result, 'data')) {
        return result;
      }

      throw new Error(`invalid api response: ${JSON.stringify(result)}`);
    });

  if (ENV.CLIENT) {
    onlySearchPromise = searchPromise;
    onlySearchPromiseSource = axios.CancelToken.source();
  }

  return searchPromise;
}

function count({
  searchParams,
  country,
  zone,
  cookies,
  requestId,
  timeout = SEARCH_API_TIMEOUT,
  testHeaders,
  userQueryId,
  userClientId,
  sessionId,
  locale,
  isAuthenticated,
  solId,
  newSince,
}: BaseAPIProps & {
  userQueryId?: any;
  newSince?: number;
}) {
  if (onlyCountPromise) {
    onlyCountPromiseSource.cancel();
  }

  const { isAuthenticatedClient, useDevAuth } =
    getAuthClientDetails(isAuthenticated);

  const config = {
    retryPolicy: { retries: 0 },
    defaultRequestConfig: {
      headers: {
        ...testHeaders,
        ...withLastKnownSolUserId(cookies['last-known-sol-user-id']),
        ...(useDevAuth ? withDevAuth(cookies.AUTH_TOKEN) : {}),
      },
    },
  };

  const httpClient = isAuthenticatedClient
    ? createAuthenticatedHttpClient(config)
    : createUnauthenticatedHttpClient(config);

  const countPromise = Promise.resolve(
    httpClient.send({
      url: getCountUrl({ isAuthenticated, cookies }),
      timeout,
      cancelToken: get(onlySearchPromiseSource, 'token'),
      label: 'jobsearch-api-count',
      params: buildApiQuery({
        userClientId,
        sessionId,
        searchQuery: searchParams,
        zone,
        country,
        locale,
        userQueryId,
        solId,
        newSince,
      }),
      headers: buildApiHeaders(requestId),
    }),
  ).then((response) => response.data);

  if (ENV.CLIENT) {
    onlyCountPromise = countPromise;
    onlyCountPromiseSource = axios.CancelToken.source();
  }

  return countPromise;
}

function counts({
  searchParams,
  country,
  zone,
  sessionId,
  userClientId,
  cookies,
  requestId,
  timeout = SEARCH_API_TIMEOUT,
  testHeaders,
  locale,
  isAuthenticated,
  solId,
}: BaseAPIProps) {
  if (onlyCountsPromise) {
    onlyCountsPromiseSource.cancel();
  }

  const { isAuthenticatedClient, useDevAuth } =
    getAuthClientDetails(isAuthenticated);

  const config = {
    omitXSeekSiteHeader: true,
    defaultRequestConfig: {
      headers: {
        ...testHeaders,
        ...(useDevAuth ? withDevAuth(cookies.AUTH_TOKEN) : {}),
      },
    },
  };

  const httpClient = isAuthenticatedClient
    ? createAuthenticatedHttpClient(config)
    : createUnauthenticatedHttpClient(config);

  const countsPromise = Promise.resolve(
    httpClient.send({
      url: getCountsUrl({ isAuthenticated, cookies }),
      timeout,
      cancelToken: get(onlySearchPromiseSource, 'token'),
      label: 'jobsearch-api-counts',
      params: buildApiQuery({
        sessionId,
        userClientId,
        searchQuery: searchParams,
        zone,
        country,
        locale,
        solId,
      }),
      headers: buildApiHeaders(requestId),
    }),
  ).then((response) => response.data);

  if (ENV.CLIENT) {
    onlyCountsPromise = countsPromise;
    onlyCountsPromiseSource = axios.CancelToken.source();
  }

  return countsPromise;
}

export default {
  search,
  counts,
  count,
};
