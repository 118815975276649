import type {
  ExperimentConfig,
  FeatureType,
} from '@seek/candidate-feature-flag';

export const REFINE_BAR_V2_FEATURE_FLAG =
  'experiment-dynamic-pills-v2-sticky-search-bar';
export const DynamicPillsV2 = 'dynamic-pills-v2';
export const StickySearchBar = 'sticky-search-bar';
export const RefineBarV2 = 'refine-bar-v2';
const refineBarV2Experiment = {
  key: REFINE_BAR_V2_FEATURE_FLAG,
  trackingNumber: 65,
  title: 'Dynamic Pills v2 Sticky Search Bar',
  type: 'string',
  variations: [
    'control',
    DynamicPillsV2,
    StickySearchBar,
    RefineBarV2,
  ] as const,
} satisfies ExperimentConfig;
export type RefineBarV2Experiment = FeatureType<typeof refineBarV2Experiment>;

export const AUTO_FOCUS_WHERE_FEATURE_FLAG = 'experiment-autofocus-where-field';
const autoFocusWhereExperiment = {
  key: AUTO_FOCUS_WHERE_FEATURE_FLAG,
  trackingNumber: 65,
  title: 'Auto Focus Where Field',
  type: 'boolean',
} satisfies ExperimentConfig;
export type AutoFocusWhereExperiment = FeatureType<
  typeof autoFocusWhereExperiment
>;

export const SAVED_AND_RECENT_SEARCHES_FEATURE_FLAG =
  'experiment-saved-and-recent-searches';
const savedAndRecentSearchesExperiment = {
  key: SAVED_AND_RECENT_SEARCHES_FEATURE_FLAG,
  trackingNumber: 65,
  title: 'Saved And Recent Searches',
  type: 'string',
  variations: ['control', 'saved-and-recent-searches'] as const,
} satisfies ExperimentConfig;
export type SavedAndRecentSearchesExperiment = FeatureType<
  typeof savedAndRecentSearchesExperiment
>;

export const experimentsToFetch: ExperimentConfig[] = [
  refineBarV2Experiment,
  autoFocusWhereExperiment,
  savedAndRecentSearchesExperiment,
];
